<template>
  <div class="card">
    <van-divider
      :style="{ color: '#555', borderColor: '#555', padding: '0 16px' }"
      >قاتەلىكتى شەشۋ جولى</van-divider
    >
    <p class="text-red">1-قاتەلىك ءتۇرى</p>
    <van-image
      class="ershida"
      fit="cover"
      lazy-load
      src="https://mmbiz.qpic.cn/mmbiz_jpg/4icPNtgBu6QdFL0HRG6xfe4KL3IueSckNoLykqRYicXq6AYglibpb5Hc5RzAfKyCEmlKcEre68eJdPVmScoOo8ibsw/0?wx_fmt=jpeg"
    />
    <p>
      جوعارىداعىداي قاتەلىك بايقالىپ كەسكىن كورىلمەسە وڭ جاق باسقى جاعىنداعى ...
      ۇش نۇكتەنى باسىپ سوسىن حانزۋشا 刷新 دەگەندى باسىڭىز.
    </p>
    <p class="text-red">2-قاتەلىك ءتۇرى</p>
    <van-image
      class="ershida"
      fit="cover"
      lazy-load
      src="https://mmbiz.qpic.cn/mmbiz_jpg/4icPNtgBu6QdFL0HRG6xfe4KL3IueSckN7lHaZBJc2KkcbcJ7FzdTWoB26hiaiaB5fYCZQGiaiaMAE0vXTzK4Qw229Q/0?wx_fmt=jpeg"
    />
    <p>
      جوعارىداعىداي قاتەلىك بايقالىپ كەسكىن كورىلمەسە وڭ جاق استىڭعى جاعىنداعى
      使用完整服务 دەگەندى باسىپ سىناپ كورىڭىز.
    </p>

    <p class="text-red">3-قاتەلىك ءتۇرى</p>
    <p>
      بۇلاردان باسقا قاتەلىك بولىپ كورىلمەسە الدىمەن تەلەفونىڭىزدىڭ تورىن
      تەكسەرىپ كورىڭىز.
    </p>

    <p class="text-red">4-قاتەلىك ءتۇرى</p>
    <p>
      جوعارىداعى ماسەلەلەر ارقىلى شەشىلمەسە ايتىس تەكشەسىنە ءسوز قالدىرىپ
      ناقتىلى ءمانجايدى ءتۇسىندىرىپ ايتىڭىز. تەحنيكالىق جاۋاپتى تەز ارادا
      ماسەلەڭىزدى شەشىپ بەرەدى.
    </p>
  </div>
</template>

<script>
export default {
  mounted () {
    document.title = 'قاتەلىكتى شەشۋ جولىن ۇيرەتۋ'
  }
}
</script>

<style lang="less" scoped>
img {
  pointer-events: auto; /* 禁止长按图片保存 */
}
.card {
  background-color: #fff;
  margin: 24px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 12px #ebedf0;

  p {
    direction: rtl;
    font-size: 28px;
    text-align: justify;
    color: #666;
    margin-bottom: 20px;
  }

  .qrCode {
    width: 100%;
  }
}
</style>
